
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { LoadingScreenInterceptor } from '../helpers/LoadingScreenInterceptor';
import { JwtInterceptor } from '../helper/Interceptor';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SharedModule } from './shared/shared.module';

import { ApiCredential } from '../apis/Credential';
import { AuthCredentialService } from '../services/Auth/Credential';
import { CloseMenuDirective } from '../directives/CloseMenu';
import { ConfigService } from '../services/Config';
import { ProbesPeriodService } from '../services/Probes/Period';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ProbesPeriodDialogComponent } from '../components/Probes/Period/Dialog';
import { MenuLanguageSelectorComponent } from '../components/Menu/Language/Selector';
import { MenuSideBarComponent } from '../components/Menu/Side/Bar';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    SharedModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule.forRoot()
  ],
  declarations: [
    AppComponent,
    CloseMenuDirective,
    ProbesPeriodDialogComponent,
    MenuLanguageSelectorComponent,
    MenuSideBarComponent
  ],
  entryComponents: [
    ProbesPeriodDialogComponent
  ],
  providers: [
    ConfigService,
    ApiCredential,
    ProbesPeriodService,
    AuthCredentialService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
  , exports: [
    // SimpleDatepickerComponent,
    // RangeDatepickerComponent
  ]
})

export class AppModule { }
