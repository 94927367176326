
import { Component, OnInit, AfterViewChecked, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import {
  Router, NavigationEnd, RouteConfigLoadStart, RouteConfigLoadEnd
} from '@angular/router';
import { AuthCredentialService } from '../services/Auth/Credential';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProbesPeriodDialogComponent } from '../components/Probes/Period/Dialog';
import { ProbesPeriodService } from '../services/Probes/Period';
import { filter, pairwise } from 'rxjs/operators';

const defaultTheme = 'classic-theme';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {

  title = 'useries-a';
  isAuth: boolean;
  curentLang: string;
  curentTheme: string = defaultTheme;
  loadingRouteConfig: boolean;
  showNavText: boolean;
  showNavTextIcon: string;
  langCodes = ['en', 'fr', 'es', 'ru', 'de'];

  private periodDialSub: Subscription;
  private routerLoadSubscriber: Subscription;
  private routerNavSubscriber: Subscription;

  constructor(
    private authService: AuthCredentialService,
    private router: Router,
    public translate: TranslateService,
    private periodService: ProbesPeriodService,
    public periodDialogRef: MatDialogRef<ProbesPeriodDialogComponent>,
    public dialog: MatDialog
  ) {
    this.init();
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn && this.sessionExpired) {
      this.router.navigateByUrl('/logout');
    }
  }

  ngOnDestroy(): void {
    if (this.periodDialSub !== undefined) {
      this.periodDialSub.unsubscribe();
    }
    if (this.routerLoadSubscriber !== undefined) {
      this.routerLoadSubscriber.unsubscribe();
    }
    if (this.routerNavSubscriber !== undefined) {
      this.routerNavSubscriber.unsubscribe();
    }
  }

  changeLang(l: string): void {
    this.translate.use(l);
    this.curentLang = l;
  }

  changeTheme(t: string): void {
    const body = document.getElementsByTagName('body')[0];
    body.className = t;
    this.curentTheme = t;
  }

  toggleNavText(): void {
    this.showNavText = !this.showNavText;
    this.refreshMenu();
  }

  ngAfterViewChecked() {
    this.refreshMenu();
  }

  changeLoadState(event: boolean): void {
    this.loadingRouteConfig = event;
  }

  openPeriodDialog(): void {
    Promise.resolve().then(() => {
      const dialogOptions = {
        width: '80%',
        height: '30%',
        data: {
          dateIn: this.periodService.dateIn,
          dateOut: this.periodService.dateOut
        }
      };
      this.periodDialogRef = this.dialog.open(
        ProbesPeriodDialogComponent, dialogOptions
      );
      this.periodDialSub = this.periodDialogRef.afterClosed().subscribe(result => {
        if (result.dateIn !== undefined && result.dateOut !== undefined) {
          this.periodService.dateIn = result.dateIn;
          this.periodService.dateOut = result.dateOut;
          this.periodService.sendPeriodChange(Math.random().toString());
        }
      });
    });
  }

  private init(): void {
    this.isAuth = this.authService.isLoggedIn();
    this.routerNavSubscriber = this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((e: any[]) => {
        this.isAuth = this.authService.isLoggedIn();
      });
    this.routerLoadSubscriber = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });
    this.translate.addLangs(this.langCodes);
    this.translate.setDefaultLang(this.langCodes[0]);
    const browserLang = this.translate.getBrowserLang();
    this.curentLang = browserLang;
    this.translate.use(
      browserLang.match(/en|fr|de|ru|es/) ? browserLang : 'en'
    );
    this.showNavText = true;
    this.showNavTextIcon = 'compress-arrows-alt';
    this.toggleNavText();
    this.changeTheme(this.curentTheme);
  }

  private refreshMenu(): void {
    const navItems: NodeList = document.querySelectorAll('.nav-link > span');
    if (navItems instanceof NodeList) {
      this.showNavTextIcon = (this.showNavText === true)
        ? 'compress-arrows-alt'
        : 'expand-arrows-alt';
      for (const navi of navItems as any as HTMLElement[]) {
        navi.style.display = (this.showNavText === true) ? 'inline' : 'none';
      }
    }
  }

  private get sessionExpired(): boolean {
    const nowSec = (new Date().getTime() / 1000) >> 0;
    return (this.authService.expiry < nowSec);
  }

}
