import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/Auth/Guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'login',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'logout',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'user',
    loadChildren: './user/user.module#UserModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'probes',
    loadChildren: './probes/probes.module#ProbesModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'calc',
    loadChildren: './calc/calc.module#CalcModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'servicing',
    loadChildren: './servicing/servicing.module#ServicingModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: './about/about.module#AboutModule'
  },
  {
    path: '**',
    loadChildren: './notfound/notfound.module#NotfoundModule'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes/*,{ enableTracing: true }*/)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
