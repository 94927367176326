
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { HttpClient } from '@angular/common/http';
import {
  faConciergeBell, faRemoveFormat,
  faRetweet, faGamepad, faCamera, faDatabase, faBug, faBookReader,
  faBook, faSignInAlt, faSignOutAlt, faHistory, faRandom,
  faExpandArrowsAlt, faCompressArrowsAlt,
  faSwimmingPool, faCalculator, faFlag, faThumbsUp, faThumbsDown,
  faDigitalTachograph,
  faInfo, faCompass, faChartLine, faChartArea, faChartBar,
  faCogs, faCog, faFilter, faBomb, faHome, faUser, faClock, faLayerGroup,
  faCircle, faSquare, faBars, faCalendar, faSquareFull,
  faWarehouse, faBed, faPencilAlt, faComment,
  faSpinner, faEye, faThermometerEmpty, faLock, faList, faPen, faTrash, faPrint
} from '@fortawesome/free-solid-svg-icons';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale, enGbLocale, deLocale } from 'ngx-bootstrap/locale';

defineLocale('fr', frLocale);
defineLocale('en', enGbLocale);
defineLocale('de', deLocale);

library.add(
  faConciergeBell, faRemoveFormat, faPrint,
  faGamepad, faCamera, faDatabase, faBug, faBookReader,
  faBook, faSignInAlt, faSignOutAlt, faHistory, faRandom,
  faExpandArrowsAlt, faCompressArrowsAlt,
  faList, faSwimmingPool, faCalculator, faFlag, faThumbsUp, faThumbsDown,
  faDigitalTachograph,
  faInfo, faEye, faThermometerEmpty, faCompass, faChartLine,
  faChartBar, faChartArea, faCogs, faCog, faFilter, faBomb, faUser,
  faSpinner, faHome, faClock, faLayerGroup, faCircle, faSquare,
  faBars, faCalendar, faSquareFull, faWarehouse, faBed,
  faPencilAlt, faComment, faLock, faPen, faTrash, faRetweet
);

import {
  MatProgressBarModule, MatExpansionModule, MatIconModule,
  MatButtonModule, MatInputModule, MatSidenavModule, MatOptionModule, MatSelectModule

} from '@angular/material';

import { BandbIconComponent } from '../../components/Icons/Bandb';
import { SpinnerIconComponent } from '../../components/Icons/Spinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SimpleDatepickerComponent } from '../../components/Date/Picker/Simple';
import { RangeDatepickerComponent } from '../../components/Date/Picker/Range';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    SpinnerIconComponent,
    BandbIconComponent,
    SimpleDatepickerComponent,
    RangeDatepickerComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    FontAwesomeModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    FontAwesomeModule,
    SpinnerIconComponent,
    BandbIconComponent,
    MatSidenavModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    TranslateModule,
    BsDatepickerModule,
    SimpleDatepickerComponent,
    RangeDatepickerComponent
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
      // providers: [ShoppingCartService, AccountService]
    };
  }
}
