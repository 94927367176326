
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { IEnvApi, IEnvDependencies, IEnvPackage } from '../model/Env/Config';

@Injectable()
export class ConfigService {
    private env = environment;

    constructor() { }

    get api(): IEnvApi {
        return this.env.api;
    }

    get name(): string {
        return this.env.name;
    }

    get version(): string {
        return this.defaultPackage.version;
    }

    get releaseName(): string {
        return this.defaultPackage.name;
    }

    get scripts(): IEnvDependencies<string> {
        return this.defaultPackage.scripts;
    }

    get dependencies(): IEnvDependencies<string> {
        return this.defaultPackage.dependencies;
    }

    get devDependencies(): IEnvDependencies<string> {
        return this.defaultPackage.devDependencies;
    }

    private get defaultPackage(): IEnvPackage {
        const def = (this.env.package['default'] === undefined)
            ? this.env.package
            : this.env.package['default'];
        return def;
    }
}
