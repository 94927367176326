import {
  Component, Output, Input, EventEmitter, ChangeDetectionStrategy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu-lang-selector',
  templateUrl: './lang-selector.html',
  styleUrls: ['./lang-selector.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MenuLanguageSelectorComponent {

  @Input() langCodes: Array<string>;
  @Input() curentLang: string;
  @Output() langChanged: EventEmitter<string> = new EventEmitter(true);

  constructor(public translate: TranslateService) { }

  changeLang(l: string): void {
    this.langChanged.emit(l);
  }

  isCurent(l: string): boolean {
    return (this.curentLang === l);
  }

}
