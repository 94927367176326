
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IAuthCredential } from '../services/Auth/ICredentials';
import { IAuthResponse } from './interfaces/IAuthResponse';
import { IUserResponse, IUserListResponse } from './interfaces/IUserResponse';
import { ConfigService } from '../services/Config';

@Injectable()
export class ApiCredential {

    private proto = '';
    private host = '';
    private endPoint = '/api/v1/auth';
    private credentials: IAuthCredential;

    constructor(private apiConfig: ConfigService, private http: HttpClient) {
        this.proto = this.apiConfig.api.proto;
        this.host = this.apiConfig.api.host;
    }

    setCredentials(cred: IAuthCredential): ApiCredential {
        this.credentials = cred;
        return this;
    }

    public login(): Observable<IAuthResponse> {
        return this.http.post<IAuthResponse>(
            this.basePointUrl,
            this.credentials,
            this.httpOptions
        );
    }

    public info(userId: number): Observable<IUserResponse> {
        return this.http.get<IUserResponse>(
            this.getUrlInfo(userId),
        );
    }

    public userList(): Observable<IUserListResponse> {
        return this.http.get<IUserListResponse>(
            this.urlListe
        );
    }

    private getUrlInfo(userId: number): string {
        return `${this.urlListe}/id/${userId}`;
    }

    private get urlListe(): string {
        return `${this.basePointUrl}/index`;
    }

    private get basePointUrl(): string {
        return `${this.baseUrl}${this.endPoint}`;
    }

    private get baseUrl(): string {
        return `${this.proto}${this.host}`;
    }

    private get httpOptions() {
        return {
            headers: this.corsHeaders,
            withCredentials: false
        };
    }

    private get corsHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Access-Control-Allow-Origin': this.baseUrl
        });
    }
}
