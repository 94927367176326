
import { Injectable } from '@angular/core';
import {
    CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthCredentialService } from './Credential';

type TActivateState = Observable<boolean> | Promise<boolean> | boolean;

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(private authService: AuthCredentialService) { }

    /**
     * check if route can be activated belong to isLoggedIn
     */
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): TActivateState {
        return this.authService.isLoggedIn();
    }

}
