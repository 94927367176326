
import { Input, Output, Component, OnChanges, EventEmitter, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';

type TRangePickerConfig = Partial<BsDatepickerConfig>;

@Component({
  selector: 'app-datepicker-range',
  templateUrl: './range-date-picker.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class RangeDatepickerComponent implements OnChanges {

  @Input() placeHolder: string;
  @Input() model: any;
  @Output() dateRangeChange: EventEmitter<Array<Date>> = new EventEmitter(true);

  locale = 'fr';
  locales = listLocales();
  bsStartValue: Date = new Date();
  bsEndValue: Date = new Date();
  pickerConfig: TRangePickerConfig;

  constructor(private localeService: BsLocaleService) {
    this.localeService.use(this.locale);
    const pConfig: TRangePickerConfig = {
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'YYYY/MM/DD'
    };
    this.pickerConfig = Object.assign({}, pConfig);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.placeHolder !== undefined && changes.placeHolder.currentValue
      !== undefined) {
      this.placeHolder = changes.placeHolder.currentValue;
    } else {
      this.placeHolder = 'default';
    }
    if (changes.model !== undefined && changes.placeHolder.currentValue
      !== undefined) {}
  }

  onModelChange(event) { }

  onValuesChange(pairDateArray: Array<Date>) {
    this.dateRangeChange.emit(pairDateArray);
  }
}
