
import { Injectable, Inject } from '@angular/core';
import { IAuthCredential } from './ICredentials';
import { ApiCredential } from '../../apis/Credential';
import { IAuthResponse } from '../../apis/interfaces/IAuthResponse';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { ITokenInfo } from '../../apis/Jwt/interfaces/ITokenInfo';
import { JwtToken } from '../../apis/Jwt/Token';
import { StorageLocal } from '../../storage/Local';
import { IUserResponse, IUserListResponse } from '../../apis/interfaces/IUserResponse';

@Injectable()

export class AuthCredentialService extends StorageLocal {

    private storageTokenKey = 'ACCESS_TOKEN';
    public storageUserKey = 'ACCESS_USER';
    public storageUserListKey = 'ACCESS_USER_LIST';

    constructor(private api: ApiCredential) {
        super();
    }

    public auth(credentials: IAuthCredential): Observable<IAuthResponse> {
        this.api.setCredentials(credentials);
        return this.api.login();
    }

    public save(res: IAuthResponse) {
        this.storageSet(this.storageTokenKey, res);
    }

    public isLoggedIn(): boolean {
        return this.token !== null;
    }

    public get token(): string {
        const storageToken = this.storageGet(this.storageTokenKey);
        if (storageToken === null) {
            return storageToken;
        }
        const session: IAuthResponse = storageToken;
        return (session.data.token !== null) ? session.data.token : null;
    }

    public get expiry(): number {
        return (new JwtToken(this.token)).expiry;
    }

    public logout() {
        this.storageRemove(this.storageTokenKey);
        this.storageRemove(this.storageUserKey);
    }

    public get userInfo(): Observable<IUserResponse> {
        const storageUser = this.storageGet(this.storageUserKey);
        if (storageUser !== null) {
            return of({ data: storageUser } as IUserResponse);
        }
        // Ex : simulate extra response delay
        // return this.api.info(uid).pipe(delay(3000));
        const uid = parseInt(this.tokenInfo.data.id, 10);
        return this.api.info(uid);
    }

    public get userList(): Observable<IUserListResponse> {
        const storageUserList = this.storageGet(this.storageUserListKey);
        if (storageUserList !== null) {
            return of({ data: storageUserList } as IUserListResponse);
        }
        return this.api.userList();
    }

    public get tokenInfo(): ITokenInfo {
        const ca = this.token;
        if (ca === null) {
            return {};
        }
        const k = ca.split('.')[1];
        const info = JSON.parse(window.atob(k));
        return info;
    }

}
