import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-icon-spinner',
  templateUrl: './spinner-icon.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SpinnerIconComponent {

  @Input() public isRunning: boolean;

  constructor() {
    this.isRunning = false;
   }

}
