
export abstract class StorageLocal {

    public storageSet(key: string, res: any): void {
        localStorage.setItem(key, JSON.stringify(res));
    }

    public storageRemove(key: string): void {
        localStorage.removeItem(key);
    }

    public storageGet(key: string): any {
        return JSON.parse(localStorage.getItem(key));
    }

    public storageHas(key: string): boolean {
        return (localStorage.getItem(key) !== null);
    }

    public storageGetEntries(): Array<string> {
        const entries = [];
        const ll = localStorage.length;
        for (let i = 0; i < ll; i++) {
            entries.push(localStorage.key(i));
        }
        return entries;
    }
}
