
import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[appMenuClose]'
})

export class CloseMenuDirective {

    @Input() menu: HTMLElement;

    constructor(private element: ElementRef) { }

    @HostListener('click')
    private onClick() {
        this.menu.classList.remove('show');
    }
}
