
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  dateIn: Date;
  dateOut: Date;
}

export interface IDateRange {
  endDate: Date;
  startDate: Date;
}

@Component({
  selector: 'app-probe-period',
  templateUrl: './period.component.html',
  styleUrls: ['./period.component.scss']
})

export class ProbesPeriodDialogComponent {

  model: IDateRange;
  stringPeriod: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<ProbesPeriodDialogComponent>,
    public translate: TranslateService,
  ) {

    this.init();
  }

  private init(): void {
    this.model = { startDate: this.data.dateIn, endDate: this.data.dateOut };
    this.stringPeriod = this.currentPeriodAsString;
  }

  get currentPeriodAsString(): string {
    let stringPeriod = '';
    if (this.data.dateIn !== undefined && this.data.dateOut !== undefined) {
      const dateInString = this.dateStringFormater(this.data.dateIn);
      const dateOutString = this.dateStringFormater(this.data.dateOut);
      stringPeriod = dateInString + ' - ' + dateOutString;
    }
    return stringPeriod;
  }


  dateRangeChanged(dateArray: Array<Date>) {
    this.data = {
      dateIn: dateArray[0],
      dateOut: dateArray[1]
    };
  }

  onCancelClick(): void {
    this.dialogRef.close(this.data);
  }

  private dateStringFormater(d: Date): string {
    const dopts = {
      timeZone: 'Europe/Paris'
    };
    return d.toLocaleDateString('fr-FR', dopts);
  }
}




