import { Component } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-datepicker-simple',
  templateUrl: './simple-date-picker.html'
})
export class SimpleDatepickerComponent {

  locale = 'fr';
  locales = listLocales();
  bsValue: Date = new Date();

  constructor(private localeService: BsLocaleService) {
    this.localeService.use(this.locale);
  }

}
