
import { ITokenInfo } from '../../apis/Jwt/interfaces/ITokenInfo';

export class JwtToken {

    private rawToken: string;
    private decodedToken: ITokenInfo;

    constructor(responseToken: string) {
        this.rawToken = responseToken;
        this.decode();
        return this;
    }

    public get expiry(): number {
        this.decode();
        return this.decodedToken.exp;
    }

    public get decoded(): ITokenInfo {
        return this.decodedToken;
    }

    private decode(): void {
        if (this.rawToken === null) {
            this.decodedToken = {};
        } else {
            const payload = this.rawToken.split('.')[1];
            this.decodedToken = JSON.parse(window.atob(payload));
        }
    }
}
