
import {
  Component, Input, Output, EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './sidebar.html',
  styleUrls: ['./sidebar.scss'],
})

export class MenuSideBarComponent {

  @Input() isAuth: boolean;
  @Output() loadStateChanged: EventEmitter<boolean> = new EventEmitter(true);
  @Output() periodRequired: EventEmitter<boolean> = new EventEmitter(true);

  constructor(
    public translate: TranslateService
  ) {}

  askPeriod(): void {
    this.periodRequired.emit(true);
  }

}
