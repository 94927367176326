import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icon-bandb',
  templateUrl: './bandb-icon.html'
})

export class BandbIconComponent implements OnInit, OnChanges {

  constructor() { }

  ngOnInit(): void {
    console.log(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }

}
