
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProbesPeriodService {

    private periodChangeSubject = new Subject<any>();
    private locale = 'fr-FR';
    private offset = 7200;
    private timeZone = 'Europe/Paris';
    private in: number;
    private out: number;

    constructor() {
        if (this.initialized === false) {
            this.setToday();
        }
    }

    get initialized(): boolean {
        return (this.in !== undefined && this.out !== undefined);
    }

    sendPeriodChange(changed: any) {
        this.periodChangeSubject.next({ hello: Math.random() });
    }

    clearPeriodChange() {
        this.periodChangeSubject.next();
    }

    getPeriodChange(): Observable<any> {
        return this.periodChangeSubject.asObservable();
    }

    get tsPeriodAsString(): string {
        return `${this.in.toString()}-${this.out.toString()}`;
    }

    get datePeriodAsString(): string {
        return `${this.dateInLocale} - ${this.dateOutLocale}`;
    }

    get tsPeriodStrictAsString(): string {
        const iprev = this.in - this.dayMsDuration;
        const onext = this.out + this.dayMsDuration;
        return `${iprev.toString()}-${onext.toString()}`;
    }

    get tsIn(): number {
        return this.in;
    }

    set tsIn(tsIn: number) {
        this.in = tsIn + this.offset;
    }

    get tsOut(): number {
        return this.out;
    }

    set tsOut(tsOut: number) {
        this.out = tsOut + this.offset;
        if (this.equals) {
            this.in -= this.dayMsDuration;
        }
    }

    set dateIn(i: Date) {
        this.tsIn = this.toTimeStamp(i);
    }

    get dateIn(): Date {
        return new Date(this.in * 1000);
    }

    set dateOut(o: Date) {
        this.tsOut = this.toTimeStamp(o);
    }

    get dateOut(): Date {
        return new Date(this.out * 1000);
    }

    get dateInLocale(): string {
        return this.formatDateToLocale(this.in * 1000);
    }

    get dateOutLocale(): string {
        return this.formatDateToLocale(this.out * 1000);
    }

    setToday(): void {
        this.tsOut = this.tsIn = this.toTimeStamp(undefined);
    }

    private equals(): boolean {
        return this.in === this.out;
    }

    private toTimeStamp(d: Date | undefined): number {
        const dt: number = (d === undefined) ? this.tsNow : d.getTime();
        return Math.round(dt / 1000);
    }

    private get tsNow(): number {
        return Date.now();
    }

    private get dayMsDuration(): number {
        return 3600 * 24;
    }

    private formatDateToLocale(i: number): string {
        const localDateOption = { timeZone: this.timeZone };
        return new Date(i).toLocaleDateString(this.locale, localDateOption);
    }
}
